import React, { useState, useEffect, useRef, useCallback } from "react";
import "./App.css";

const SIZE = 4;

// Function to generate a solvable board
const generateBoard = () => {
  // Generate an array representing the board
  const board = Array.from(
    { length: SIZE * SIZE },
    (_, i) => (i + 1) % (SIZE * SIZE)
  );
  // Shuffle the array until it's solvable
  return shuffleArray(board);
};
// Function to shuffle an array
const shuffleArray = (array) => {
  let shuffled = false;
  while (!shuffled) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    // Check if the shuffled array is solvable and not already solved
    if (isSolvable(array) && !isSolved(array)) {
      shuffled = true;
    }
  }
  return array;
};

const isSolvable = (board) => {
  let inversions = 0;
  for (let i = 0; i < board.length; i++) {
    for (let j = i + 1; j < board.length; j++) {
      if (board[i] > board[j] && board[i] !== 0 && board[j] !== 0) {
        inversions++;
      }
    }
  }
  const emptyRow = SIZE - Math.floor(board.indexOf(0) / SIZE);
  return (inversions + emptyRow) % 2 === 0;
};

const isSolved = (board) => {
  for (let i = 0; i < board.length - 1; i++) {
    if (board[i] !== i + 1) {
      return false;
    }
  }
  return board[board.length - 1] === 0;
};

const App = () => {
  const [board, setBoard] = useState(generateBoard());
  const [emptyIndex, setEmptyIndex] = useState(board.indexOf(0));
  const [moves, setMoves] = useState(0);
  const [time, setTime] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isSolvableConfig, setIsSolvableConfig] = useState(true);
  const [helpClicks] = useState(0);

  const timerRef = useRef(null);

  useEffect(() => {
    setIsSolvableConfig(isSolvable(board));
  }, [board]);

  useEffect(() => {
    if (isStarted && !isPaused) {
      startTimer();
    } else {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [isPaused, isStarted]);

  const startTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const handleTileClick = (index) => {
    if (isMovable(index) && isStarted && !isPaused) {
      const newBoard = [...board];
      newBoard[emptyIndex] = board[index];
      newBoard[index] = 0;
      setBoard(newBoard);
      setEmptyIndex(index);
      setMoves((prevMoves) => prevMoves + 1);
    }
  };

  const isMovable = useCallback(
    (index) => {
      const emptyRow = Math.floor(emptyIndex / SIZE);
      const emptyCol = emptyIndex % SIZE;
      const tileRow = Math.floor(index / SIZE);
      const tileCol = index % SIZE;
      return Math.abs(emptyRow - tileRow) + Math.abs(emptyCol - tileCol) === 1;
    },
    [emptyIndex]
  );

  const renderTile = (value, index) => (
    <div
      key={index}
      className={`tile ${value === 0 ? "empty" : ""}`}
      onClick={() => handleTileClick(index)}
    >
      {value !== 0 && value}
    </div>
  );

  const handleStartPause = () => {
    if (!isStarted) {
      setIsStarted(true);
      setIsPaused(false);
    } else {
      setIsPaused(!isPaused);
    }
  };

  const handleResume = () => {
    if (!isStarted) {
      setIsStarted(true);
      setIsPaused(false);
    } else {
      setIsPaused(false);
    }
  };

  const handleReset = () => {
    const newBoard = generateBoard();
    if (!isSolvable(newBoard)) {
      alert("Not solvable");
      return;
    }
    setBoard(newBoard);
    setEmptyIndex(newBoard.indexOf(0));
    setMoves(0);
    setTime(0);
    setIsPaused(false);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  const helpMe = useCallback(() => {
    const movableTiles = [];
    for (let i = 0; i < board.length; i++) {
      if (isMovable(i)) {
        movableTiles.push(i);
      }
    }
    if (movableTiles.length > 0) {
      const randomIndex = Math.floor(Math.random() * movableTiles.length);
      const movableIndex = movableTiles[randomIndex];
      const newBoard = [...board];
      newBoard[emptyIndex] = newBoard[movableIndex];
      newBoard[movableIndex] = 0;
      setBoard(newBoard);
      setEmptyIndex(movableIndex);
      setMoves((prevMoves) => prevMoves + 1);
    } else {
      alert("No movable tiles left!");
    }
  }, [board, emptyIndex, isMovable]);

  useEffect(() => {
    if (helpClicks > 0) {
      helpMe();
    }
  }, [helpClicks, helpMe]);

  return (
    <div className="App">
      <div className="container">
        <div className="header">
          <div className="info">
            <span className="text">Moves</span>
            <span className="num">{moves}</span>
          </div>
          <div className="timepoint">
            <span className="text">Time</span>
            <span className="num">{formatTime(time)}</span>
          </div>
          <div className="controls">
            <button onClick={handleStartPause} id="start_pause_button">
              {!isStarted ? "PLAY" : isPaused ? "START" : "PAUSE"}
            </button>
            <button onClick={handleReset} id="reset_button">
              Reset
            </button>
            <button
              onClick={handleReset}
              id="shuffle_button"
              disabled={!isSolvableConfig}
            >
              Shuffle
            </button>
          </div>
        </div>
        <h1 className="game_heading">
          FIFTEEN PUZZLE <br /> GAME
        </h1>
        <button onClick={helpMe} id="help_button">
          Help Me
        </button>
      </div>
      <div className={`board ${isPaused ? "paused" : ""}`}>
        {board.map((value, index) => renderTile(value, index))}
      </div>
      {(isPaused || !isStarted) && (
        <div className="overlay" onClick={handleResume}>
          {!isStarted ? "PLAY" : "PAUSED"}
        </div>
      )}
    </div>
  );
};

export default App;
